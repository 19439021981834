import { template as template_f211899e2e60430aa19b01ea4c6786d4 } from "@ember/template-compiler";
const FKLabel = template_f211899e2e60430aa19b01ea4c6786d4(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
