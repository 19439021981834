import { template as template_22792f33f6dd443e8de934a2a43ff16e } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_22792f33f6dd443e8de934a2a43ff16e(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
