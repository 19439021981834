import { template as template_bc37f7dedc9540a9adf0f9da2557ca98 } from "@ember/template-compiler";
const SidebarSectionMessage = template_bc37f7dedc9540a9adf0f9da2557ca98(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
