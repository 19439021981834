import { template as template_141f579c98d0402ba2fa50bca9d982e4 } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const PasskeyLoginButton = template_141f579c98d0402ba2fa50bca9d982e4(`
  <DButton
    @action={{@passkeyLogin}}
    @icon="user"
    @label="login.passkey.name"
    class="btn-social passkey-login-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default PasskeyLoginButton;
